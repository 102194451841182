import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import Consult from "../components/Consult"
import { graphql } from "gatsby"
import { useLandingPageData } from "../data/useLandingPageData"
import Breadcrumb from "../components/Breadcrumb"
import { GatsbyImage } from "gatsby-plugin-image"
import Button from "../components/Button"
import rehypeRaw from "rehype-raw"
import ReactMarkdown from "react-markdown"
import { useMetadata } from "../data/use-metadata"
import { GatsbySeo } from "gatsby-plugin-next-seo/src/meta/gatsby-seo"
import { useModalUpdate } from "../contexts/ModalContext"
import Link from "../components/Link"

const ServiceFeature = ({ data }) => {
  const {
    image: {
      file: { url },
      imageTitle,
    },
    title,
    excerpt,
  } = data

  return (
    <div>
      <img src={url} alt={imageTitle} className="h-14 mx-auto sm:h-24" />
      <p className="-translate-y-6 z-10 text-2xl !leading-normal text-center font-bold text-neutral-800 sm:text-4xl sm:-translate-y-8">
        {title}
      </p>
      <p className="-translate-y-6 mt-2 leading-tight text-center text-neutral-600 sm:text-xl sm:leading-normal sm:mt-4 sm:-translate-y-8">
        {excerpt}
      </p>
    </div>
  )
}

const ServiceItem = ({ data, isFirst }) => {
  const {
    title,
    content: { content },
    image: { gatsbyImageData },
    ctaName,
    ctaUrl,
  } = data

  const setIsModal = useModalUpdate()

  return (
    <li
      className={`pb-12 sm:pb-20 lg:flex lg:gap-16 ${
        isFirst ? "pt-0 sm:pt-0" : "pt-12 sm:pt-20"
      }`}
    >
      <GatsbyImage
        image={gatsbyImageData}
        alt={title}
        className="block w-full object-cover lg:h-72 lg:flex-shrink-0 lg:w-auto lg:aspect-3/2"
      />
      <div>
        <p className="mt-4 text-2xl !leading-normal font-bold text-neutral-800 sm:text-4xl lg:mt-0">
          {title}
        </p>
        <ReactMarkdown rehypePlugins={[rehypeRaw]} className="markdown service">
          {content}
        </ReactMarkdown>
        {ctaUrl.includes("lin.ee") ? (
          <Button isLink={false} onClick={setIsModal}>
            {ctaName}
          </Button>
        ) : (
          <Link as={ctaUrl.includes("http") ? "a" : "link"} to={ctaUrl}>
            <Button>{ctaName}</Button>
          </Link>
        )}
      </div>
    </li>
  )
}

const ServiceTemplate = ({ data }) => {
  const { consultGatsbyImageData, consultTitle } = useLandingPageData()

  const {
    contentfulServicePage: {
      coursePlanning: { title, slug },
      description: { description },
      image: {
        gatsbyImageData,
        file: { url },
        title: imageTitle,
      },
      serviceFeature,
      serviceItem,
    },
  } = data

  const crumbs = [
    {
      name: "首頁",
      url: "/",
    },
    {
      name: title,
      url: `/${slug}`,
    },
  ]

  const { siteUrl } = useMetadata()
  const canonical = `${siteUrl}/${slug}`

  const serviceFeatureBlock = serviceFeature?.map(item => (
    <ServiceFeature key={item.id} data={item} />
  ))

  const serviceItemBlock = serviceItem?.map((item, idx) => {
    const isFirst = idx === 0
    return <ServiceItem key={item.id} data={item} isFirst={isFirst} />
  })

  return (
    <Layout>
      <GatsbySeo
        title={title}
        description={description}
        canonical={canonical}
        openGraph={{
          url: canonical,
          title,
          images: [
            {
              url: `https:${url}`,
              width: 1200,
              height: 630,
              alt: title,
            },
          ],
        }}
      />

      <div className="my-12 wrapper">
        <Breadcrumb crumbs={crumbs} />
      </div>

      <div className="wrapper mb-12 sm:mb-16">
        <div className="lg:grid lg:grid-cols-3 lg:gap-16 lg:items-center">
          <div className="lg:col-span-2">
            <h1 className="mb-8 text-3xl leading-normal tracking-wide font-bold text-neutral-800 sm:text-5xl">
              {title}
            </h1>
            <p className="mb-8 !leading-normal text-neutral-600 sm:text-lg lg:mb-0">
              {description}
            </p>
          </div>
          <GatsbyImage
            image={gatsbyImageData}
            alt={imageTitle}
            className="block w-full lg:col-span-1"
          />
        </div>
      </div>

      {serviceFeature && (
        <div className="px-6 py-16 bg-neutral-50 lg:px-0">
          <div className="wrapper">
            <h2 className="text-2xl text-center font-bold text-amber-400 mb-12 sm:text-4xl sm:mb-16">
              服務特色
            </h2>
            <div className="grid gap-10 sm:gap-8 lg:grid-cols-3 lg:gap-16">
              {serviceFeatureBlock}
            </div>
          </div>
        </div>
      )}

      {serviceItem && (
        <div className="wrapper mt-24 mb-12 sm:mt-36 sm:mb-16">
          <h2 className="mb-8 text-3xl leading-normal tracking-wide font-bold text-neutral-800 sm:mb-12 sm:text-5xl">
            服務項目
          </h2>
          <ul className="divide-y divide-neutral-400">{serviceItemBlock}</ul>
        </div>
      )}

      <Consult
        gatsbyImageData={consultGatsbyImageData}
        consultTitle={consultTitle}
      />
    </Layout>
  )
}

ServiceFeature.propTypes = {
  data: PropTypes.object,
}

ServiceItem.propTypes = {
  data: PropTypes.object,
  isFirst: PropTypes.bool,
}

ServiceTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ServiceTemplate

export const pageQuery = graphql`
  query ($slug: String!) {
    contentfulServicePage(coursePlanning: { slug: { eq: $slug } }) {
      description {
        description
      }
      coursePlanning {
        title
        slug
      }
      image {
        gatsbyImageData(
          width: 699
          height: 466
          placeholder: BLURRED
          quality: 100
        )
        file {
          url
        }
        title
      }
      serviceFeature {
        id
        image {
          file {
            url
          }
          title
        }
        title
        excerpt
      }
      serviceItem {
        id
        title
        content {
          content
        }
        image {
          gatsbyImageData(
            width: 705
            height: 470
            placeholder: BLURRED
            quality: 100
          )
        }
        ctaName
        ctaUrl
      }
    }
  }
`
